const getCmdbFields = (arr) => {
  const list = [];
  arr.forEach((el) => {
    if (el.classType === 'cmdb') {
      list.push(el);
      if (el.childs) [].push.apply(list, getCmdbFields(el.childs));
    }
  });
  return list;
};

export default getCmdbFields;
