<template>
  <div class="setting-item s-cmdb-object cmdb-controller">
    <esmp-select
      v-if="formattedCmdbControls.length"
      placeholder="Родительский CMDB"
      :value="valueObject.parentCmdbControlId"
      @on-change="setValue($event, 'parentCmdbControlId')"
    >
      <esmp-select-option
        v-for="item in formattedCmdbControls"
        :key="item.id"
        :value="item.id"
      >
        {{ item.text }}
      </esmp-select-option>
    </esmp-select>

    <esmp-select
      placeholder="Класс/Подкласс"
      :value="valueObject.searchType"
      @on-change="setValue($event, 'searchType')"
      class="cmdb-controller__item"
    >
      <esmp-select-option
        v-for="item in classSubclassValues"
        :key="item.value"
        :value="item.value"
      >
        {{ item.text }}
      </esmp-select-option>
    </esmp-select>

    <esmp-select
      placeholder="Класс"
      filterable
      :disabled="allDisabled"
      :loading="loading.classNameInput"
      :value="valueObject.className"
      @on-change="setValue($event, 'className')"
      class="cmdb-controller__item"
    >
      <esmp-select-option
        v-for="item in classValues"
        :key="item.value"
        :value="item.value"
      >
        {{ item.value }}
      </esmp-select-option>
    </esmp-select>

    <esmp-select
      v-if="valueObject.className"
      placeholder="Значение по умолчанию"
      :value="valueObject.cmdbDefaultValueId"
      @on-change="setValue($event, 'cmdbDefaultValueId')"
      class="cmdb-controller__item"
    >
      <esmp-select-option
        v-for="item in configItemsValues"
        :key="item.id"
        :value="item.value"
      >
        {{ item.text }}
      </esmp-select-option>
    </esmp-select>
    <div
      v-if="valueObject.cmdbDefaultValueId"
      class="setting-item__child-settings"
    >
      <div
        class="setting-item__child-setting setting-item__child-setting--small-offset"
        v-for="child in localSetting.childs"
        :key="child.id"
      >
        <esmp-checkbox
          v-if="child.type === 'checkbox'"
          v-model="child.value"
        >
          {{ child.name }}
        </esmp-checkbox>
      </div>
    </div>

    <esmp-progress
      v-if="loading.configItemsInput"
      :percent="99"
      :stroke-width="3"
      status="active"
      hide-info
      class="cmdb-controller__item"
    />

    <esmp-input
      v-if="configItemsValues.length > 14"
      label="Поиск по КЕ"
      v-model="configItemQuery"
      :disabled="allDisabled || !valueObject.className"
      class="cmdb-controller__ke-search-wrapper cmdb-controller__item"
    />

    <div
      class="cmdb-controller__ke-wrapper cmdb-controller__item"
      v-if="configItemsValues.length"
    >
      <template v-for="(item, index) in configItemsValues">
        <div
          v-if="item.text.toLowerCase().includes(configItemQuery.toLowerCase())"
          class="cmdb-controller__ke"
          :key="`ke_${item.value}_${index}`"
        >
          <span>{{ item.text }}</span>
          <div class="cmdb-controller__ke-icons">
            <span @click="showAddInfoModal(item)" class="cmdb-controller__ke-icon">
              <esmp-icon
                name="help"
                title="Добавить подсказку"
                :fill="item.info ? 'blue' : ''"
              />
            </span>
            <span @click="showConnectGroupModal(item)" class="cmdb-controller__ke-icon">
              <esmp-icon
                name="connect"
                title="Добавить связь с группой"
                :disabled="!groups.length"
                :fill="item.connectedGroups ? 'blue' : ''"
              />
            </span>
          </div>
        </div>
      </template>
    </div>

    <div v-if="configItemsValues.length" class="cmdb-controller__wrapper">
      <esmp-checkbox
        v-model="isAttrFilterShowed"
        class="cmdb-controller__item"
      >
        Фильтровать по атрибутам
      </esmp-checkbox>
      <cmdb-attr-filter
        v-if="isAttrFilterShowed"
        :disabled="allDisabled"
        :select-items="attributesList"
        :value="valueObject.configItemFilter"
        @change="setValue($event, 'configItemFilter')"
        class="cmdb-controller__item"
      />
      <esmp-checkbox
        v-model="valueObject.isAttributeAsInfo"
        @input="setValue($event, 'isAttributeAsInfo')"
        class="cmdb-controller__item"
      >
        Использовать подсказку из атрибута
      </esmp-checkbox>
      <esmp-select
        v-if="valueObject.isAttributeAsInfo"
        label="Атрибут"
        :value="valueObject.attributeAsInfo"
        @on-change="setValue($event, 'attributeAsInfo')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in attributesList"
          :key="item"
          :value="item"
        >
          {{ item }}
        </esmp-select-option>
      </esmp-select>

      <esmp-checkbox
        v-model="valueObject.isSortByAttributeValue"
        @input="setValue($event, 'isSortByAttributeValue')"
        class="cmdb-controller__item"
      >
        Использовать сортировку по атрибуту
      </esmp-checkbox>
      <esmp-select
        v-if="valueObject.isSortByAttributeValue"
        label="Атрибут"
        :value="valueObject.attributeToSortBy"
        @on-change="setValue($event, 'attributeToSortBy')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in attributesList"
          :key="item"
          :value="item"
        >
          {{ item }}
        </esmp-select-option>
      </esmp-select>

      <esmp-checkbox
        v-model="valueObject.isAttributeAsTitle"
        @input="setValue($event, 'isAttributeAsTitle')"
        class="cmdb-controller__item"
      >
        Отобразить атрибут КЕ вместо названия
      </esmp-checkbox>
      <esmp-select
        v-if="valueObject.isAttributeAsTitle"
        label="Атрибут"
        :value="valueObject.attributeAsTitle"
        @on-change="setValue($event, 'attributeAsTitle')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in attributesList"
          :key="item"
          :value="item"
        >
          {{ item }}
        </esmp-select-option>
      </esmp-select>

      <esmp-checkbox
        v-model="valueObject.multipleValues"
        @input="setValue($event, 'multipleValues')"
        class="cmdb-controller__item"
      >
        Множественный выбор
      </esmp-checkbox>

      <esmp-checkbox
        v-model="valueObject.addApprovement"
        @input="setValue($event, 'addApprovement')"
        class="cmdb-controller__item"
      >
        Добавить согласование
      </esmp-checkbox>
      <esmp-select
        v-if="valueObject.addApprovement"
        placeholder="Значение для согласования"
        :value="valueObject.attributeAsApprovement"
        @on-change="setValue($event, 'attributeAsApprovement')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in attributesList"
          :key="item"
          :value="item"
        >
          {{ item }}
        </esmp-select-option>
      </esmp-select>
      <esmp-select
        v-if="valueObject.addApprovement"
        placeholder="Дополнительное значение для согласования"
        :value="valueObject.backupAttributeAsApprovement"
        @on-change="setValue($event, 'backupAttributeAsApprovement')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in attributesList"
          :key="item"
          :value="item"
        >
          {{ item }}
        </esmp-select-option>
      </esmp-select>
      <esmp-checkbox
        v-if="valueObject.addApprovement"
        v-model="valueObject.isApproverFromReserveClass"
        @input="setValue($event, 'isApproverFromReserveClass')"
        class="cmdb-controller__item"
      >
        Необходим согласующей из другого класса?
      </esmp-checkbox>
      <esmp-select
        v-if="valueObject.isApproverFromReserveClass"
        placeholder="Резервный класс"
        :value="valueObject.reserveClassName"
        @on-change="setValue($event, 'reserveClassName')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in classValues"
          :key="item.value"
          :value="item.value"
        >
          {{ item.value }}
        </esmp-select-option>
      </esmp-select>
      <esmp-select
        v-if="valueObject.isApproverFromReserveClass"
        placeholder="Атрибут резервного класса"
        :value="valueObject.attributeAsApprovementFromReserveClass"
        :loading="loading.reserveClassInput"
        @on-change="setValue($event, 'attributeAsApprovementFromReserveClass')"
        class="cmdb-controller__item"
      >
        <esmp-select-option
          v-for="item in reserveAttributesList"
          :key="item"
          :value="item"
        >
          {{ item }}
        </esmp-select-option>
      </esmp-select>
      <esmp-checkbox
        v-if="valueObject.addApprovement"
        v-model="valueObject.addTechnicalApprover"
        @input="setValue($event, 'addTechnicalApprover')"
        class="cmdb-controller__item"
      >
        Технический согласующий
      </esmp-checkbox>
      <validation-provider
        rules="email"
        name="«Email технического согласующего»"
        v-slot="v"
        tag="div"
      >
        <esmp-input
          v-if="valueObject.addTechnicalApprover"
          label="Email технического согласующего"
          v-model="valueObject.technicalApprover"
          class="cmdb-controller__item"
          :error-message="v.errors[0] || ''"
          :options="{ type: 'email' }"
          @input="setValue($event, 'technicalApprover')"
        />
      </validation-provider>
    </div>

    <modal-cmdb-ke-add-comment
      :item="selectedKE"
      v-model="addInfoModalShowed"
      @set="addKEInfo"
    />

    <modal-cmdb-ke-connect-group
      v-if="groups.length"
      :item="selectedKE"
      v-model="connectGroupModalShowed"
      :groups="groups"
      @set="addKEGroup"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import getGroups from '@/components/service-form/helpers/getGroups';
import getCmdbFields from '@/components/service-form/helpers/getCmdbFields';
import ModalCmdbKeAddComment from './ModalCmdbKeAddComment.vue';
import ModalCmdbKeConnectGroup from './ModalCmdbKeConnectGroup.vue';
import CmdbAttrFilter from './CmdbAttrFilter.vue';

const getDefaultValueObject = () => ({
  searchType: '',
  className: '',
  cmdbDefaultValueId: null,
  parentCmdbControlId: null,
  configItemFilter: [],
  configItems: {},

  // Отобразить атрибут КЕ вместо названия
  attributeAsTitle: '',
  isAttributeAsTitle: false,

  // Использовать подсказку из атрибута
  isAttributeAsInfo: false,
  attributeAsInfo: '',

  // Использовать сортировку по атрибуту
  isSortByAttributeValue: false,
  attributeToSortBy: '',

  // Добавить согласование
  addApprovement: false,
  attributeAsApprovement: '',
  backupAttributeAsApprovement: '',

  // Выбор согласующего для другого класса
  isApproverFromReserveClass: false,

  // Резервный класс
  reserveClassName: '',
  attributeAsApprovementFromReserveClass: '',

  // Технический согласующий
  addTechnicalApprover: false,
  technicalApprover: '',

  // Множественный выбор
  multipleValues: false,
});

const classSubclassValues = [
  {
    text: 'Класс',
    value: 'class',
  },
  {
    text: 'Подкласс',
    value: 'subclass',
  },
];

const source = 'otrs1';

export default {
  name: 'SCmdbObject',
  components: {
    CmdbAttrFilter,
    ModalCmdbKeAddComment,
    ModalCmdbKeConnectGroup,
  },
  inject: ['globalState'],
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      // valueObject -- итоговый объект CMDB, требующийся для сохранения формы с CMDB на сервере
      valueObject: getDefaultValueObject(),
      classSubclassValues: Object.freeze(classSubclassValues),
      classValues: [],
      selectedKE: {},
      configItemsValues: [],
      configItemQuery: '',
      isAttrFilterShowed: false,
      addInfoModalShowed: false,
      connectGroupModalShowed: false,
      attributesList: [],
      reserveAttributesList: [],
      loading: {
        classNameInput: false,
        configItemsInput: false,
        attributesInput: false,
        reserveClassInput: false,
      },
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    allDisabled() {
      return !this.valueObject.searchType;
    },
    groups() {
      return getGroups(this.globalState.elements);
    },
    cmdbControls() {
      return getCmdbFields(this.globalState.elements);
    },
    formattedCmdbControls() {
      return this.cmdbControls
        .map((item) => ({
          id: item.techId,
          text: item.settings.find((setting) => setting.techName === 'name')
            .value,
          value: item.techId,
        }))
        .filter((item) => item.id !== this.id);
    },
    attributeAsTitle() {
      return this.valueObject.attributeAsTitle;
    },
    isAttributeAsTitle() {
      return this.valueObject.isAttributeAsTitle;
    },
    addApprovement() {
      return this.valueObject.addApprovement;
    },
    addTechnicalApprover() {
      return this.valueObject.addTechnicalApprover;
    },
    multipleValues() {
      return this.valueObject.multipleValues;
    },
    isApproverFromReserveClass() {
      return this.valueObject.isApproverFromReserveClass;
    },
  },
  methods: {
    async setValue(value, name) {
      switch (name) {
      case 'configItemFilter':
        if (Array.isArray(value)) {
          this.$set(this.valueObject, name, value);
        } else {
          this.$set(this.valueObject, name, [value]);
        }
        break;
      case 'reserveClassName':
        this.$set(this.valueObject, name, value);
        this.$set(this.valueObject, 'attributeAsApprovementFromReserveClass', '');
        await this.getReserveAttributesData();
        break;
      case 'className':
        this.$set(this.valueObject, name, value);
        this.$set(this.valueObject, 'configItems', {});
        this.$set(this.valueObject, 'cmdbDefaultValueId', null);
        await Promise.all([this.getConfigItems(), this.getAttributesData()]);
        break;
      default:
        this.$set(this.valueObject, name, value);
        break;
      }

      this.setting.value = cloneDeep(this.valueObject);
    },
    async setValueObjectOnLoad() {
      const value = this.localSetting?.value || null;
      if (value?.className) {
        await Promise.all([
          this.getAttributesData(value.className),
          this.getConfigItems(
            value.className,
            value.attributeAsTitle,
          ),
        ]);
        Object.keys(value).forEach((key) => {
          this.$set(this.valueObject, key, value[key]);

          if (key === 'configItemFilter' && value[key].length) {
            this.isAttrFilterShowed = true;
          }

          if (key === 'configItems') {
            Object.keys(value[key]).forEach((configItemId) => {
              const configItem = this.configItemsValues.find(
                (item) => item.id === configItemId,
              );

              if (configItem) {
                configItem.info = value[key][configItemId].info;
                configItem.connectedGroups = value[key][
                  configItemId
                ].connectedGroups;
              }
            });
          }
        });
      }

      if (value?.reserveClassName) {
        this.getReserveAttributesData();
      }
    },
    getClassData() {
      this.loading.classNameInput = true;
      return this.$API.cmdb.getClasses()
        .then(({ data: classData }) => {
          this.classValues = classData.map((item) => ({
            text: item.itemContent,
            value: item.itemContent,
          }));
        })
        .finally(() => {
          this.loading.classNameInput = false;
        });
    },
    getAttributesData(className) {
      this.loading.attributesInput = true;
      return this.$API.cmdb.getAttributes({
        source,
        className: className || this.valueObject.className,
      })
        .then(({ data: attributesList }) => {
          this.attributesList = attributesList;
        })
        .finally(() => {
          this.loading.attributesInput = false;
        });
    },
    getReserveAttributesData() {
      if (!this.valueObject.reserveClassName) return Promise.resolve();

      this.loading.reserveClassInput = true;
      return this.$API.cmdb.getAttributes({
        source,
        className: this.valueObject.reserveClassName,
      })
        .then(({ data: attributesList }) => {
          this.reserveAttributesList = attributesList;
        })
        .finally(() => {
          this.loading.reserveClassInput = false;
        });
    },
    getConfigItems(className, attributeAsTitle) {
      this.loading.configItemsInput = true;
      return this.$API.cmdb.getConfigItems({
        source,
        className: className || this.valueObject.className,
        attributeNameAsItemName:
          attributeAsTitle || this.valueObject.attributeAsTitle || null,
      })
        .then((configItemsData) => {
          this.configItemsValues = configItemsData.map((item) => ({
            text: item.itemContent,
            value: item.itemId,
            id: item.itemId,
          }));
        })
        .finally(() => {
          this.loading.configItemsInput = false;
        });
    },
    showAddInfoModal(item) {
      this.selectedKE = item;
      this.addInfoModalShowed = true;
    },
    showConnectGroupModal(item) {
      this.selectedKE = item;
      this.connectGroupModalShowed = true;
    },
    addKEInfo(info) {
      const configItems = cloneDeep(this.valueObject.configItems);

      this.selectedKE.info = info;
      configItems[this.selectedKE.id] = this.selectedKE;
      this.setValue(configItems, 'configItems');
      this.selectedKE = {};
    },
    addKEGroup(groups) {
      const configItems = cloneDeep(this.valueObject.configItems);

      this.selectedKE.connectedGroups = groups;
      configItems[this.selectedKE.id] = this.selectedKE;
      this.setValue(configItems, 'configItems');
      this.selectedKE = {};
    },
  },
  mounted() {
    this.setValueObjectOnLoad();
    this.getClassData();
  },
  watch: {
    attributeAsTitle() {
      this.getConfigItems();
    },
    isAttributeAsTitle(isSelected) {
      if (!isSelected && this.valueObject.attributeAsTitle) {
        this.setValue('', 'attributeAsTitle');
        this.getConfigItems();
      }
    },
    isAttrFilterShowed(isSelected) {
      if (!isSelected) {
        this.setValue([], 'configItemFilter');
      }
    },
    addApprovement(isSelected) {
      if (!isSelected) {
        this.setValue(false, 'isApproverFromReserveClass');
      }
      if (!isSelected && (this.valueObject.attributeAsApprovement || this.valueObject.backupAttributeAsApprovement)) {
        this.setValue('', 'attributeAsApprovement');
        this.setValue('', 'backupAttributeAsApprovement');
        this.setValue('', 'technicalApprover');
        this.setValue(false, 'addTechnicalApprover');
        this.getConfigItems();
      }
      if (!isSelected && this.valueObject.technicalApprover) {
        this.setValue('', 'technicalApprover');
      }
    },
    isApproverFromReserveClass(isSelected) {
      if (!isSelected) {
        this.setValue('', 'reserveClassName');
        this.setValue('', 'attributeAsApprovementFromReserveClass');
      }
    },
  },
};
</script>

<style lang="scss">
.cmdb-controller {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-top: 10px;
  }

  &__ke-search-wrapper {
    margin-bottom: 0 !important;
  }

  &__ke-wrapper {
    max-height: (24px + 15px) * 5;
    overflow: hidden;
    overflow-y: auto;
  }

  &__ke {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-icons {
      display: flex;
      margin-left: auto;
    }
    &-icon {
      width: 24px;
      cursor: pointer;
      margin-left: 5px;
    }

  }

  &__attr-select {
    padding-left: 20px;
  }
}
</style>
